export const formatarValorMonetario = (valor) => {
    if (!valor) return 'R$ 0,00';

    return (
        'R$ ' +
        new Intl.NumberFormat('pt-BR', {
            maximumFractionDigits: 2,
            minimumFractionDigits: 2
        }).format(parseFloat(valor))
    );
};

export const formatarValor = (valor, qtdeDecimais) => {
    if (!qtdeDecimais) qtdeDecimais = 2;

    return new Intl.NumberFormat('pt-BR', {
        maximumFractionDigits: qtdeDecimais,
        minimumFractionDigits: qtdeDecimais
    }).format(parseFloat(valor));
};

export const arredondarValorMonetario = (valor, digits) => {
    return Number(parseFloat(valor).toFixed(digits));
};

export const arredondarValorToEven = (number, places) => {
    // var multiplier = Math.pow(10, places + 2); // get two extra digits
    // var fixed = Math.floor(number * multiplier); // convert to integer
    // fixed += 44; // round down on anything less than x.xxx56
    // fixed = Math.floor(fixed / 100); // chop off last 2 digits
    // return fixed / Math.pow(10, places);

    const mult = Math.pow(10, places);

    return Math.round(number * mult) / mult;
};

export const decimalKeepPlaces = (value, decimalPlaces) => {
    if (!decimalPlaces) decimalPlaces = 2;

    if (parseFloat(value) == parseInt(value) && !isNaN(value)) return value;

    return value.toFixed(decimalPlaces).replace('.', ',');
};

export default {
    moneyRound(value) {
        if (!value) {
            return 0;
        }

        return Number(parseFloat(value).toFixed(2));
    },

    isNull(value, newValue) {
        if (value) {
            return value;
        }

        return newValue;
    }
};
