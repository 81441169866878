<template>
  <div id="principal" class="shadow-sm h-100 d-block">
    <div class="d-flex flex-column bg-white">
      <div class="input-group input-group-sm shadow-sm card-header-filter px-3 pt-3">
        <input
          v-model="codigoProdutoPesquisado"
          type="text"
          class="form-control form-control-sm"
          ref="inputBuscaProdutos"
          id="lmxta-busca-produtos"
          @keydown.enter.tab.prevent="onPesquisarProduto()"
          autocomplete="off"
          placeholder="Pesquisar Produto"
        />

        <div class="input-group-append">
          <button v-if="codigoProdutoPesquisado && codigoProdutoPesquisado.length" class="btn-icon-xs btn-clear" @click="codigoProdutoPesquisado = null" title="Limpar campo de filtro">
            <i class="icon icon-lx-close"></i>
          </button>
          <button class="btn btn-primary btn-sm float-right" @click="onPesquisarProduto">
            <span class="text-center">
              <i class="icon icon-lx-search"></i>
            </span>
          </button>
        </div>
        <!-- Abrir câmera para ler código de barras (somente mobile) -->
        <div class="input-group-append" v-if="isMobile && videoDevice">
          <button class="btn btn-secondary btn-sm float-right" @click="abrirLeitorCodigoBarras()">
            <span class="text-center">
              <i class="fas fa-camera"></i>
            </span>
          </button>
        </div>
        <hr class="m-0 p-0 w-100" />

        <div class="d-flex justify-content-between w-100">
          <div class="d-flex justify-content-between align-items-center mt-2">
            <switches class="mx-2" color="yellow" v-model="exibirSomenteProdutosComSaldo"></switches>
            <span class="text-muted h6">Somente Produtos com Saldo</span>
          </div>
          <div class="size-font-label text-muted pt-2" v-if="produtoSearched && produtos.length">
            {{ produtos.length }}
            Registro{{ produtos.length > 1 ? 's' : '' }}
          </div>
        </div>
      </div>
    </div>
    <div v-if="produtoSearched && produtos.length" class="d-flex flex-column shadow-sm bg-white shadow-hover card-data only-overflow-y mb-2 mx-1">
      <span class="text-muted h6 m-2"
        >Produto pesquisado:
        <span class="label-orange">{{ codigoProdutoPesquisadoAux }}</span>
      </span>
      <div class="row d-flex justify-content-center">
        <div class="form-group col-12 col-sm-6 col-md-6">
          <div class="row">
            <div class="col-12">
              <div class="shadow-sm card mb-3" v-for="(produto, index) in listaProdutos" v-bind:key="index">
                <div class="card-header bg-primary text-white size-font-label m-0 py-0 pl-2">
                  {{ produto.DescricaoProduto }}
                </div>
                <div class="card-body m-0 p-0">
                  <div class="d-flex m-2">
                    <div class="text-right d-flex flex-column justify-content-end align-items-end mr-1">
                      <span class="label-orange">Cód. Produto:</span>
                      <span class="label-orange">Referência:</span>
                      <span class="label-orange">Preço Venda:</span>
                      <span class="label-orange">Saldo Total Empresa {{ empresaSelecionada.Id }}:</span>
                    </div>
                    <div class="text-right d-flex flex-column align-items-start">
                      <span class="text-muted size-font-label">
                        {{ produto.CodigoProduto }}
                      </span>
                      <span class="text-muted size-font-label">
                        {{ produto.Referencia ? produto.Referencia : '-' }}
                      </span>
                      <span class="text-muted size-font-label">
                        {{ formatarMascararValorMonetario(produto.PrecoVenda) }}
                      </span>
                      <span class="text-muted size-font-label">
                        {{ produto.SaldoWMS + produto.SaldoForaWMS }}
                      </span>
                    </div>
                  </div>

                  <div class="m-2">
                    <div v-for="deposito in produto.DepositosWMS" :key="deposito.IdDeposito">
                      <div class="font-weight-bold px-1 mt-1 d-flex align-items-center justify-content-between border-bottom bg-card-secondary">
                        <span class="size-font-label text-muted">{{ deposito.NomeDeposito }}</span>
                        <span class="size-font-label text-muted"
                          >Saldo Total:
                          <strong class="text-primary"> {{ deposito.SaldoDisponivel }}</strong>
                        </span>
                      </div>
                      <ul class="list-group">
                        <li class="list-group-item d-flex justify-content-between align-items-center" v-for="(localizacao, indexLoc) in deposito.Localizacoes" v-bind:key="indexLoc">
                          <span class="text-muted size-font-label">
                            <strong
                              :class="{
                                'label-orange ': localizacao.LocalizacaoPrimaria,
                              }"
                            >
                              {{ localizacao.LocalizacaoPrimaria ? 'LP' : 'LS' }}:
                            </strong>
                            {{ localizacao.Sigla }}
                          </span>
                          <span
                            :class="{
                              'badge badge-success ': localizacao.SaldoDisponivelLocalizacao > 0,
                              'badge badge-danger': localizacao.SaldoDisponivelLocalizacao === 0,
                              'badge badge-warning ': localizacao.SaldoDisponivelLocalizacao < 0,
                            }"
                          >
                            {{ localizacao.SaldoDisponivelLocalizacao }}
                          </span>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="text-right mr-2 my-0 p-0 fz-70 text-muted">{{ index + 1 }} / {{ produtos.length }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="text-center card-info-search">
      <div v-if="exibeMensagemCentral">
        <div>
          <span class="text-center mensagem-titulo">
            <img :src="iconeCentral" :alt="iconeInf" />
          </span>
        </div>
        <div class="mensagem-central">
          <span class="text-uppercase text-muted fz-95">
            {{ mensagemCentral }}
          </span>
        </div>
      </div>
    </div>

    <div class="fixed-bottom text-right" v-if="produtoSearched && produtos.length > 0">
      <button :class="['btn btn-default', isMobile ? 'btn-block' : 'm-3']" @click="onVoltar()">Voltar</button>
    </div>

    <leitor-codigo-barras v-model="leitorCodigoBarras.retorno" :ativo="leitorCodigoBarras.estaAtivo" />
  </div>
</template>

<script>
import loading from '@/common/loading';
import { formatarValorMonetario } from '@/common/number';
import { mapGetters } from 'vuex';
import Switches from 'vue-switches';
import swal from '@/common/alerts';
import leitorCodigoBarras from '@/components/leitor-codigo-barras.vue';

//URLs Icones/Imagens
const imgIconInfo = require('@/assets/Images/linx-icon-ilustrativo/info.png');
const imgIconSearch = require('@/assets/Images/linx-icon-ilustrativo/search.png');

export default {
  name: 'produto-pesquisa',
  components: {
    Switches,
    leitorCodigoBarras,
  },
  data() {
    return {
      tipoMensagem: { Sucesso: 1, Erro: 2, Aviso: 3 },
      codigoProdutoPesquisado: null,
      codigoProdutoPesquisadoAux: null,
      exibirSomenteProdutosComSaldo: false,
      produtoFiltrado: false,
      listaProdutos: [],
      iconeInf: imgIconInfo,
      iconeSearch: imgIconSearch,
      leitorCodigoBarras: {
        estaAtivo: false,
        retorno: null,
      },
    };
  },
  mounted() {
    this.$refs.inputBuscaProdutos.focus();
  },
  destroyed() {
    this.$store.dispatch('produtos/resetProdutos');
  },
  watch: {
    'leitorCodigoBarras.retorno': function (result) {
      if (this.leitorCodigoBarras.estaAtivo) {
        this.leitorCodigoBarras.estaAtivo = false;
      }

      if (result && result.codigoBarras) {
        this.codigoProdutoPesquisado = result.codigoBarras;
        this.onPesquisarProduto();
      }
    },
  },
  computed: {
    ...mapGetters({
      isMobile: 'appData/ehWMSMobile',
      produtos: 'produtos/produtos',
      videoDevice: 'uiState/hasVideoDevice',
      empresaSelecionada: 'auth/empresaSelecionada',
    }),
    produtoSearched: function () {
      return this.produtoFiltrado;
    },
    mensagemCentral: function () {
      return this.produtoSearched && this.produtos.length == 0
        ? 'Nenhum registro encontrado com os filtros informados ou não possui localização configurada'
        : 'Busque por: Código de Barras, Código do Produto, Descrição ou Referência';
    },
    iconeCentral: function () {
      return this.produtoSearched && this.produtos.length == 0 ? imgIconInfo : imgIconSearch;
    },
    exibeMensagemCentral: function () {
      return (this.produtoSearched && this.produtos.length == 0) || !this.produtoSearched;
    },
  },
  methods: {
    async onPesquisarProduto() {
      var self = this;
      self.leitorCodigoBarras.retorno = null;
      if (!self.codigoProdutoPesquisado) {
        swal.exibirToastAlerta('Informe o produto a ser pesquisado.');
        return false;
      }

      loading.exibir();
      var params = {
        NomeRefProd: self.codigoProdutoPesquisado,
        SomenteProdutosComSaldo: self.exibirSomenteProdutosComSaldo,
      };

      try {
        await self.$store.dispatch('produtos/obterProdutos', params);
        self.carregaListaProdutos();
        self.produtoFiltrado = true;
        self.codigoProdutoPesquisadoAux = self.codigoProdutoPesquisado;
        self.codigoProdutoPesquisado = null;
        this.$refs.inputBuscaProdutos.focus();
      } catch (err) {
        swal.exibirMensagemErro('Não foi possível buscar o produto.');
        console.log(err);
      } finally {
        loading.ocultar();
      }
    },
    carregaListaProdutos: function () {
      var self = this;
      self.listaProdutos = self.lodash.map(self.produtos, function (p) {
        var localizacoesWMS = self.lodash.groupBy(p.Localizacoes, function (i) {
          return i.ControlaLocalizacaoWMS ? 'Controla' : 'NaoControla';
        });
        p.DepositosWMS = [];
        var controla = self.lodash.orderBy(localizacoesWMS.Controla || [], 'NomeDeposito');
        var depositos = self.lodash.groupBy(controla, function (i) {
          return `${i.NomeDeposito}|${i.IdDeposito}`;
        });
        self.lodash.forIn(depositos, function (value, key) {
          var dep = key.split('|');
          var localizacoes = value.filter(function (l) {
            return l.IdProdutosLocalizacoesDepositos != 0;
          });
          if (localizacoes.length > 0) {
            p.DepositosWMS.push({
              IdDeposito: dep[1],
              NomeDeposito: dep[0],
              SaldoDisponivel: self.lodash.sumBy(value, 'SaldoDisponivelLocalizacao'),
              Localizacoes: localizacoes,
            });
          }
        });
        p.SaldoWMS = self.lodash.sumBy(localizacoesWMS.Controla || [], 'SaldoDisponivelLocalizacao');
        p.SaldoForaWMS = self.lodash.sumBy(localizacoesWMS.NaoControla || [], 'SaldoDisponivelLocalizacao');
        return p;
      });
    },
    formatarMascararValorMonetario: function (valor) {
      return formatarValorMonetario(valor);
    },
    onVoltar: function () {
      this.produtoFiltrado = false;
      this.codigoProdutoPesquisado = null;
      this.$refs.inputBuscaProdutos.focus();
    },
    abrirLeitorCodigoBarras: function () {
      this.leitorCodigoBarras.estaAtivo = true;
    },
  },
};
</script>

<style scoped>
#containerProdutosScroll {
  flex: 1 1 1px;
}

.only-overflow-y {
  overflow-y: auto;
  overflow-x: hidden;
}

@media (max-height: 500px) {
  .esconde-botoes {
    display: none !important;
  }
}

.size-font-label {
  font-size: 0.875rem;
}
.label-orange {
  position: relative;
  text-align: left;
  font-family: Dosis, sans-serif;
  font-size: 0.875rem;
  font-weight: bolder;
  color: #ffb200;
}
.identificacao-footer {
  height: 50px;
  background-color: white;
  margin-top: 0.25rem;
}

.card-inteiro {
  height: calc(100vh - 100px);
}
.tamanho-card-mobile {
  height: calc(100% - 100px);
}
.card-header-filter {
  /* margin-top: 58px; */
  margin-top: 0px;
  background-color: #f9f9f9;
}
.card-data {
  /* margin-top: 65px; */
  margin-top: 0px;
}

.card-info-search {
  margin-top: 200px;
}

.bg-card-secondary {
  background-color: #d3e3eb;
}

::v-deep .vue-switcher.vue-switcher-theme--default.vue-switcher-color--yellow div {
  background-color: #e0c58b !important;
}

::v-deep .vue-switcher.vue-switcher-theme--default.vue-switcher-color--yellow div:after {
  background-color: #ffb200 !important;
}

::v-deep .vue-switcher.vue-switcher--unchecked.vue-switcher-theme--default.vue-switcher-color--yellow div {
  background-color: #d1d1d1 !important;
}

::v-deep .vue-switcher.vue-switcher.vue-switcher--unchecked.vue-switcher-theme--default.vue-switcher-color--yellow div:after {
  background-color: #a3a2a2 !important;
}

.btn-clear {
  color: #ee9ca4;
  background-color: #fff;
  border-color: #f3828e;
}
</style>
